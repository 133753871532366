<template>
  <LiefengContent>
    <template #title>谁查看了
      <Button
        v-if="$route.query.isLineHome"
        type="error"
        @click="
          $router.push({
            path: '/emailgroup',
            query: { menuId: $route.query.menuId,isLineHome:$route.query.isLineHome },
          })
        "
        >返回</Button
      >
    </template>
    <template #toolsbarRight>
      <Input
        style="width: 260px"
        v-model.trim="userName"
        placeholder="按姓名查询"
        search
        @on-search="search"
        enter-button="查询"
      ></Input>
     <Button
        v-if="$route.query.isLineHome == ''"
        type="error"
        icon="ios-arrow-back"
        @click="
          $router.push({
            path: '/emailgroup',
            query: { menuId: $route.query.menuId },
          })
        "
        >返回</Button
      >
    </template>
    <template #contentArea>
      <LiefengTable
        :tableData="tableData"
        :talbeColumns="talbeColumns"
        :loading="loading"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100, 200]"
        :total="total"
        :fixTable="true"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/emailsee')

import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";

export default {
  data() {
    return {
      tableData: [],
      talbeColumns: [
        {
          title: "姓名",
          key: "userName",
          align: "center",
          minWidth: 160,
        },
        {
          title: "查看时间",
          key: "viewTime",
          align: "center",
          minWidth: 160,
        },
        {
          title: "查看次数",
          key: "count",
          align: "center",
          minWidth: 160,
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      userName: ''
    };
  },
  methods: {
    search() {
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get(
        "/sendmsg/pc/chat/communityMessage/queryCommunityMessageViewPage",
        {
          messageId: this.$route.query.id,
          page: obj.page,
          pageSize: obj.pageSize,
          userName: this.userName,
        }
      ).then((res) => {
        if (res.code == 200 && res.dataList) {
            res.dataList.map(item => {
                item.viewTime = item.viewTime ? this.$core.formatDate(new Date(item.viewTime),'yyyy-MM-dd hh:mm:ss') : '';
            })
          this.tableData = res.dataList;
          this.total = res.maxCount;
          this.page = res.currentPage;
          this.pageSize = res.pageSize;
          this.loading = false;
        }else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
      }).catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
/deep/#toolsbarRight{
  height: 30px;
}
</style>